.searchArea {
  display: inline-block;
  position: absolute;
  font-size: 90%;
  top: 0;
  left: 0;
  margin: 0.2vh;
  z-index: 1 !important;
}

.results {
  margin-top: 0.8vh;
  border-radius: 3px;
  background: #fff;
  overflow: auto;
  max-height: 50vh;
}
.results div {
  padding: 0.3vh 0.6vw;
  border-bottom: 1px solid lightgray;
}
.results div:hover {
  cursor: pointer;
  background-color: whitesmoke;
}
.searchError {
  background-color: rgb(255, 234, 234);
  border-radius: 3px;
  color: darkred;
  margin-top: 0.8vh;
  padding: 0.6vmin;
}
